const jobs = [
    {
        title: 'Data Analyst',
        location: 'Global, Remote',
        description: 'Valhallapass.ai is a blockchain trading and analytics platform that aggregates on-chain data, analyzes them, and notifies the best opportunities in web3 markets. Whether you are a newcomer or an experienced trader, the features we provide will boost your trading experience to the highest levels.',
        details: `
            About the role
            <br /><br />
            We’re looking for a founding Data Analyst to come join our brand new Data Analytics and Data Science team.
            <br /><br />
            This role is a unique opportunity to help valhallapass.ai grow and make better decisions faster by taking a lead on owning data, and analysing, understanding, and providing actionable insights
            <br /><br />
            There’s lots to do; some areas of immediate ownership and impact include ensuring data availability and reliability across valhallapass.ai, LTV forecasts, and customer growth (new adds, churn, retention)
            <br /><br />
            Responsibilities
            <br /><br />
            <ul>
                <li>Own the data, definition and reporting on KPIs for our product squads and marketing teams, including helping identify the right metrics to optimize for strategically and bringing accountability and transparency on our progress</li>
                <li>Partner with teams to help define goals for these KPIs and influence OKRs and squad prioritization in a data-driven way</li>
                <li>Build the right models and tools that allow squads to build better products for our users and generate insights that help us grow faster and more efficiently </li>
                <li>Help shape the overall vision for Analytics and Data Science function at valhallapass.ai, including how we grow and scale the team and capabilities </li>
            </ul>
            Your experience
            <br /><br />
            <ul>
                <li>Proven track record and experience in practicing analytics in a marketing, product, or finance organization</li>
                <li>Highly proficient with database, analytics and visualization technologies (e.g. SQL, BI tools (we use Metabase), Python/other programming languages)</li>
                <li>Passionate about some of the following domains: marketing and attribution, customer journey and funnel optimisation, trends in tracking & measurement (front-end tracking, Attribution), growth, pricing/unit  economics</li>
                <li>[nice to have] experience with experiments, including A/B testing and providing guidance on topics like statistical power, sample sizes, significance and are able to make strong, data-based recommendations to marketing teams</li>
            </ul>
            Who you are
            <br /><br />
            <ul>
                <li>Self-starter and able to operate independently, with a high degree of autonomy while maintaining a focus on delivery and excellence at work</li>
                <li>Comfortable with ambiguity and able to adapt to changes in priorities and plans</li>
                <li>Empathetic, kind, curious, and sincere, with bonus points for crypto fluency</li>
            </ul>
        `
    },
    {
        title: 'Site Reliability Engineer',
        location: 'Global, Remote',
        description: 'Valhallapass.ai is a blockchain trading and analytics platform that aggregates on-chain data, analyzes them, and notifies the best opportunities in web3 markets. Whether you are a newcomer or an experienced trader, the features we provide will boost your trading experience to the highest levels.',
        details: `
            About the role
            <br /><br />
            As SRE you will use your broad engineering skill set to ensure the reliability of our systems.
            <br /><br />
            Your contributions to the team will have a big impact, as you help our customers navigate the crypto markets.
            <br /><br />
            You can be located anywhere in the world, as our work is 100% online.
            <br /><br />
            The position is full-time.
            <br /><br />
            Responsibilities
            <br /><br />
            <ul>
                <li>Engage in and improve the whole lifecycle of services - from inception and design, deployment, operation, and refinement</li>
                <li>Support services before they go live through activities such as system design consulting, developing software platforms and frameworks, capacity planning, and launch reviews</li>
                <li>Maintain services once they are live by measuring and monitoring availability, latency, and overall system health</li>
                <li>Scale systems sustainably through mechanisms like automation; evolve systems by pushing for changes that improve reliability and velocity</li>
                <li>Practice sustainable incident response and blameless postmortems</li>
                <li>Build CI/CD pipelines</li>
            </ul>
            Are you the right person for this role?
            <br /><br />
            The ideal candidate for us has:
            <br /><br />
            <ul>
                <li>3+ years work experience as an SRE or similar role</li>
                <li>Direct experience with the crypto markets (either professionally or as a hobby)</li>
                <li>Experience in computer science, data structures, algorithms and software design</li>
                <li>Expertise in designing, analyzing, and troubleshooting large-scale distributed systems</li>
                <li>Systematic problem-solving approach, coupled with effective communication skills and a sense of drive</li>
                <li>Good understanding of software security architecture</li>
                <li>Fluency in Python</li>
                <li>Experience with Linux, BASH and standard DevOps tools</li>
            </ul>
            The following are nice-to-haves (but not a requirement!):
            <ul>
                <li>Experience with GCP (BigQuery, Composer, Cloud Run, Logging, Monitoring, IAM)</li>
            </ul>
        `
    },
    {
        title: 'Account Executive',
        location: 'Global, Remote',
        description: 'Valhallapass.ai is a blockchain trading and analytics platform that aggregates on-chain data, analyzes them, and notifies the best opportunities in web3 markets. Whether you are a newcomer or an experienced trader, the features we provide will boost your trading experience to the highest levels.',
        details: `
            <br /><br /> 
            We are backed by some of the best investors in the world (e.g., a16z, Accel, Tiger, GIC) and have great ambitions to shape the web3 world.
            <br /><br /> 
            About the Role
            <br /><br /> 
            As an Account Executive at valhallapass.ai, you will shape our company’s growth trajectory by building relationships with prospective clients and turning them into happy valhallapass.ai users. You are used to building value in competitive situations and enjoy working on products that require deep product understanding, combined with technical knowledge. You are naturally analytical and enjoy digging into investment decisions and helping customers quantify their investment decisions. You get excited about prospecting and are capable of independently leading a sales cycle from start to finish.
            <br /><br /> 
            What You’ll Do
            <br /><br /> 
            <ul>
                <li>Develop a named account list with account plans for winning and expanding business with companies in our target segments</li>
                <li>You'll develop and manage a named account list and be responsible for the full pre-sales cycle from prospecting to signing, including all commercial negotiations</li>
                <li>Engage in business-level and technical conversations at multiple levels of the organization, and clearly articulate valhallapass.ai’s value proposition</li>
                <li>Collaborate with product, design, and engineering teams to provide informed recommendations into valhallapass.ai’s product roadmap that enhance the user experience</li>
                <li>You’ll shape valhallapass.ai’s trajectory – as an early member of the team, you will also have the opportunity to help build the sales engine and define the sales strategy that fuels the growth of the company!</li>
            </ul>
            What You Need
            <br /><br />
            <ul>
                <li>Minimum 4 years of quota-carrying experience in a full cycle closing role with strong prospecting, qualifying, negotiating, and closing skills</li>
                <li>Experience working in a high growth tech company</li>
                <li>Experience selling Software-as-a-Service products</li>
                <li>Ability to discuss valhallapass.ai’s value proposition with potential users and decision makers </li>
                <li>Experience with outbound prospecting and conducting product demonstrations</li>
                <li>Consultative sales approach and comfortable leveraging analytical & quantitative skills</li>
                <li>Consistent track record of hitting or exceeding sales targets in a fast-paced environment</li>
                <li>High adaptability and understanding of change within the evolution of a startup</li>
                <li>Excellent verbal and written communication skills</li>
                <li>Extraordinary sense of ownership and a builder mindset</li>
            </ul>
            Nice to Haves
            <br /><br />
            <ul>
                <li>Crypto-native</li>
            </ul>
            `
    },
    {
        title: 'Senior Software Engineer (Full-Stack)',
        location: 'Global, Remote',
        description: 'Valhallapass.ai is a blockchain trading and analytics platform that aggregates on-chain data, analyzes them, and notifies the best opportunities in web3 markets. Whether you are a newcomer or an experienced trader, the features we provide will boost your trading experience to the highest levels.',
        details: `
            About the role
            <br /><br />
            As a software engineer, you will work on a specific project critical to valhallapass.ai's needs with opportunities to switch teams and projects as you and our fast-paced business grow and evolve.
            <br /><br />
            We need our engineers to be versatile, display leadership qualities and be enthusiastic to take on new problems across the full-stack as we continue to push the crypto industry forward.
            <br /><br />
            We're looking for engineers who bring fresh ideas from all areas, including information retrieval, data analytics, large-scale system design, security, artificial intelligence; the list goes on and is growing every day.
            <br /><br />
            You can be located anywhere in the world, as our work is 100% online. The position is full-time.
            <br /><br />
            Responsibilities
            <br /><br />
            Be a leader with a focus on building an enjoyable, productive team
            <br /><br />
            <ul>
                <li>Design, develop, test, deploy, maintain, and improve software</li>
                <li>Manage priorities, deadlines, and deliverables</li>
                <li>Lead engineering efforts from planning and organization to execution and delivery to solving complex engineering problems</li>
                <li>Take initiative and be responsible for technical solutions to data quality and workflow challenges</li>
                <li>Are you the right person for this role?</li>
            </ul>
            The ideal candidate for us has:
            <br /><br />
            <ul>
                <li>7+ years work experience as a software engineer or similar role</li>
                <li>Leadership ambitions</li>
                <li>Direct experience with the crypto markets (either professionally or as a hobby)</li>
                <li>Experience in computer science, data structures, algorithms and software design</li>
                <li>Experience in building data pipelines</li>
                <li>An eye for detail, but is pragmatic and able to get things done fast</li>
                <li>Great written communication skills in English</li>
                <li>An ability to self-organize in a remote-first work environment</li>
            </ul>
            The following are nice-to-haves (but not a requirement!):
            <ul>
                <li>Fluency with SQL and Python</li>
                <li>Experience with React</li>
            </ul>
        `
    },
    {
        title: 'Communications Lead',
        location: 'Global, Remote',
        description: 'Valhallapass.ai is a blockchain trading and analytics platform that aggregates on-chain data, analyzes them, and notifies the best opportunities in web3 markets. Whether you are a newcomer or an experienced trader, the features we provide will boost your trading experience to the highest levels.',
        details: `
            About the role
            <br /><br />
            As the Communications Lead, you will lead and drive the PR & communications strategy and communications for valhallapass.ai. You will play a key role in "the future of finance", and help us to achieve our mission of surfacing the signal in blockchain data.
            <br /><br />
            You can be located anywhere in the world, as our work is 100% online.
            <br /><br />
            The position is full-time.
            <br /><br />
            What will you be doing?
            <br /><br />
            <ul>
                <li>Lead, develop and execute the public relations and communications strategy, go-to-market product launches, across online, offline and social media & community channels</li>
                <li>Stay up to date on upcoming cryptocurrency market trends, identify actionable insights, conduct competitor analysis and propose ideas and campaigns to drive company and communication goals</li>
                <li>Take end-to-end ownership of the entire communications strategy — from overseeing public relations plans, media planning, budgeting, measuring performance goals and reporting results</li>
                <li>Create guides, workflows, playbooks and optimize processes for both internal and external stakeholders</li>
            </ul>
            Are you the right person for this role?
            <br /><br />
            The ideal candidate for us has:
            <br /><br />
            <ul>
                <li>Knowledge of the valhallapass.ai platform and data analytics</li>
                <li>Prior experience with crypto, data analytics and/or fintech space</li>
                <li>At least 5 years work of public relations and/or communications experience</li>
                <li>Strong project management skills, ability to multitask, prioritize and follow through on ongoing projects and campaigns</li>
                <li>Strong writing and communication skills</li>
                <li>Drive: A self-starter with a can-do attitude. We want people who are passionate about the crypto industry, and are eager to up-skill and hone their knowledge in the crypto space</li>
                <li>Independent: Direct experience working in a remote first environment; and able to work </li>
                <li>Self-motivated: Entrepreneurial mindset with a strong sense of ownership, empathy, and determination to get things done</li>
                <li>Resilience: Humble to new ideas, and eager to learn amidst adversity and challenges</li>
                <li>Detail-oriented: Strong attention to detail and is able to get things done fast</li>
                <li>An entrepreneurial spirit and the skills to take a (rough) idea and make it reality</li>
                <li>Bonus: Proficiency in other major languages (e.g. Mandarin or Arabic)</li>
            </ul>
        `
    }
];

export default jobs;