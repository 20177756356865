import { useState, useEffect } from "react";

export const Select = ({ options, label, defaultValue, setDefaultValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
            setDefaultValue(null)
        }
    }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = () => {
        setIsOpen(true)
    }

    const handleSelect = (newValue) => {
        setIsOpen(false);
        setValue(newValue);
    }

    return (
        <div className="select">
            <span>{label}</span>
            <div className="placeholder" onClick={handleClick}>
                <span>{value ?? 'Section title'}</span>
            </div>
            <div className={isOpen ? "menu open" : "menu"}>
                {options.map((option) => (
                    <div key={option} onClick={() => handleSelect(option)}>{option}</div>
                ))}
            </div>
        </div>
    );
}

export const Input = ({ label, placeholder, ...props }) => (
    <div className="input">
        <span>{label}</span>
        <div>
            <input type="text" placeholder={placeholder} {...props} />
        </div>
    </div>
);

export const Textarea = ({ label, placeholder, ...props }) => (
    <div className="input">
        <span>{label}</span>
        <div>
            <textarea placeholder={placeholder} {...props} />
        </div>
    </div>
);
