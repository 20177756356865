import trader from '../resources/assets/trader.png';
import advisor from '../resources/assets/advisor.png';
import markets from '../resources/assets/markets.png';
import hexGraph from '../resources/assets/hex_graph.png';
import predictor from '../resources/assets/predictor.png';
import history from '../resources/assets/history.png';
import holdings from '../resources/assets/holdings.png';

import './Features.scss';

const Features = () => (
    <>
        <div className="features-heading">
            <h1>Main Features</h1>
            <span className="description">Valhallapass.ai is all about data aggregation and smart calculations. Scroll down to see the key features that we offer. Enjoy the journey !</span>
        </div>
        <section className="features">
            <div>
            <div className="feature">
                <div>
                <h2>AI Bot Trader</h2>
                <span className="description">
                    Our AI Bot never sleeps, raving your preferred blockchain, looking for Buying/Selling opportunities. Built in user-friendly UI will help you to customize AI Bot in your preferred way.
                </span>
                </div>
                <img src={trader} alt="" />
            </div>
            <div className="feature">
                <img src={advisor} alt="" />
                <div>
                <h2>AI Advisor</h2>
                <span className="description">
                    Almost the same thing as an AI Bot trader except Suggester Bot will never make a decision for you. Only easy-to-read signals based on historical data of the specific blockchain.
                </span>
                </div>
            </div>
            <div className="feature">
                <div>
                <h2>Portfolio tracker</h2>
                <span className="description">
                    We do know that checking your NFTs all over the space following massive links and names could be annoying. Meet the brand new NFT Portfolio Tracker. All your owned NFTs in one place with all necessary information e.g floor prices, statistics, volume etc.
                </span>
                </div>
                <img src={holdings} alt="" />
            </div>
            <div className="feature">
                <img src={history} alt="" />
                <div>
                <h2>Wallet Tracker</h2>
                <span className="description">
                    All profitable wallets are filtered from our bot. You will see them having specific labels around the wallet address. Also we have a feature enabled so you can label any wallet you want. Smart wallet, Whale, flipper, diamond hand, paper hand, papyrus hand labels are already attached to wallets.                </span>
                </div>
            </div>
            <div className="feature">
                <div>
                <h2>Watchlist</h2>
                <span className="description">
                    Bookmark and track your favorite NFT collections. Easy-to-use filter-enabled bot will notify any changes regardless on specific asset.
                </span>
                </div>
                <img src={markets} alt="" />
            </div>
            <div className="feature">
                <img src={hexGraph} alt="" />
                <div>
                <h2>On-chain research</h2>
                <span className="description">
                    Coming in Q4. For all nerds from nerds. Got all the information from the NFT collection contract in a well-structured way. No mess, no chaos, just hard data to rely on.
                </span>
                </div>
            </div>
            <div className="feature">
                <div>
                <h2>Price Predictor</h2>
                <span className="description">
                    This bot analyzes the wallets of NFT owners. Based on behavior of the owners, activity and volume, our bot based on algorithmic calculations will give price prediction by periods. Weekly and Monthly predictions are available. Daily prediction will be available in Q4.
                </span>
                </div>
                <img src={predictor} alt="" />
            </div>
            </div>
        </section>
    </>
);

export default Features;