import { Link } from 'react-router-dom';

const faq = [
    {
        question: 'When is the official launch of valhallapass.ai?',
        answer: 'Official date of launch is set to 27th October of 2022.'
    },
    {
        question: 'What is Valhalla pass?',
        answer: 'Valhalla pass is a ticket presented as NFT. Which will grant you LIFETIME access to our Platform.'
    },{
        question: 'What is the supply of Valhalla pass?',
        answer: 'It’s only 500. 489 - Valhalla passes. 10 - Valhalla Gold Passes. 1 - Valhalla Diamond Pass.'
    },
    {
        question: 'What’s the difference between Valhalla passes?',
        answer: <>Please follow the <Link to="/pricing">link</Link>.</>
    },
    {
        question: 'When Mint?',
        answer: 'The date of private Mint is scheduled for Aug 29. All whitelisted members will have 24 hours to mint their passes. Any leftover passes will go to the public sale.'
    },
    {
        question: 'What is the price of a Valhalla pass?',
        answer: 'For both private and public price is 10 sol.'
    },
    {
        question: 'What are the key features of valhallapass.ai ?',
        answer: <>Please follow the <Link to="/features">link</Link>.</>
    },
    {
        question: 'Do you have a Discord?',
        answer: 'Yes. We do have private discord, updating with new users every month with specific discord link.'
    },
    {
        question: 'What are the future plans?',
        answer: 'Well, after successful launch, we are planning to provide monthly limited subscriptions.'
    },
    {
        question: 'Why would the subscriptions be limited as well?',
        answer: 'It is all  because we don’t want to become another pump/dump platform'
    },
    {
        question: 'Can I contact you for business inquiries ?',
        answer: 'Drop a message to partners@valhallapass.ai'
    },
]

export default faq;
