import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { Input, Textarea } from '../common';

import pass from '../resources/assets/pass.png';

import './ContactUs.scss';

const ContactUs = () => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Submit');
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      setIsDisabled(true);
  
      emailjs.sendForm('service_0xfszhb', 'template_lfaroen', form.current, 'Mfrx1OW6-mDlISrgz')
        .then((result) => {
            setButtonText('Thanks!')
        }, (error) => {
            setButtonText('Please try again.')
        });
    };
  
    return (
        <>
            <section className="pass">
                <h1>Valhalla Pass NFT</h1>
                <span className="description">
                Collection of 500 Valhalla Pass NFTs consisting of 1 Diamond Valhalla pass, 10 Golden Valhalla passes and 489 Valhalla passes.
                </span>
                <div>
                <img src={pass} alt="" />
                <span className="description">
                    Valhalla pass is the ticket you need to reserve the spot around the table of Valhalla. Boost your trading experience with us. 
                    <br /><br />
                    Get Lifetime access to amazing features that will help you through your trading journey. Only 500 Passes will ever exist. And only limited subscriptions will be available in the near future.
                    <br /><br />
                    There are 3 types of passes, all of them granting to its owner Lifetime access to valhallapass.ai
                    <br /><br />
                    Read the difference between passes here.
                    <br /><br />
                    Valhalla awaits!
                </span>
                </div>
            </section>
            <section className="contact-form">
                <h2>Contact us</h2>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="names">
                        <Input placeholder="First Name" name="first_name" />
                        <Input placeholder="Last Name" name="last_name" />
                    </div>
                    <Input placeholder="Email" name="email" />
                    <Input placeholder="Subject" name="subject" />
                    <Textarea placeholder="Message" name="message" />
                    <button type="submit" className={isDisabled ? 'primary disabled' : 'primary'}>{buttonText}</button>
                </form>
            </section>
        </>
    );
};

export default ContactUs;