import { useState } from 'react';
import pass from '../resources/assets/pass.png';

import questions from './questions';

import './FAQ.scss';

const Accordion = ({ question, answer }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={`accordion ${isExpanded ? 'expanded' : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
            <div className="question">
                <span>{question}</span>
                <span>+</span>                
            </div>
            <div className="answer description">
                {answer}
            </div>
        </div>
    );
}

const FAQ = () => (
    <>
        <section className="pass">
            <h1>Valhalla Pass NFT</h1>
            <span className="description">
            Collection of 500 Valhalla Pass NFTs consisting of 1 Diamond Valhalla pass, 10 Golden Valhalla passes and 489 Valhalla passes.
            </span>
            <div>
            <img src={pass} alt="" />
            <span className="description">
                Valhalla pass is the ticket you need to reserve the spot around the table of Valhalla. Boost your trading experience with us. 
                <br /><br />
                Get Lifetime access to amazing features that will help you through your trading journey. Only 500 Passes will ever exist. And only limited subscriptions will be available in the near future.
                <br /><br />
                There are 3 types of passes, all of them granting to its owner Lifetime access to valhallapass.ai
                <br /><br />
                Read the difference between passes here.
                <br /><br />
                Valhalla awaits!
            </span>
            </div>
        </section>
        <section className="faq">
            <h1>Frequently Asked Questions</h1>
            <div className="questions">
                {questions.map((props) => <Accordion {...props} />)}
            </div>
        </section>
    </>
);

export default FAQ;