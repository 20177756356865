import { useState, useEffect, useMemo } from 'react';

import silverPass from '../resources/assets/silver_pass.png';
import goldPass from '../resources/assets/gold_pass.png';
import platinumPass from '../resources/assets/platinum_pass.png';
import solana from '../resources/assets/solana.png';
 

import './Pricing.scss';

const features = [
    { title: 'Market Analytics', basic: '+', pro: '+', premium: '+' },
    { title: 'Wallet tracker', basic: '+', pro: '+', premium: '+' },
    { title: 'Portfolio tracker', basic: '+', pro: '+', premium: '+' },
    { title: 'Watchlist', basic: '10', pro: '25', premium: 'Unlimited' },
    { title: 'Custom Alerts', basic: '5', pro: '15', premium: 'Unlimited' },
    { title: 'Hot Contracts', basic: '', pro: '+', premium: '+' },
    { title: 'Price Predictor', basic: '', pro: '+', premium: '+' },
    { title: 'On-chain research', basic: '', pro: '+', premium: '+' },
    { title: 'AI Bot trader', basic: '', pro: '', premium: '+' },
    { title: 'AI Bot adviser', basic: '', pro: '', premium: '+' },
    { title: 'Team board meet-ups', basic: '', pro: '', premium: '+' },
]

const pricing = {
    monthly: [499, 1299, 2899],
    quarterly: [1199, 3119, 6959]
}

const Pricing = () => {
    const [isQuarterly, setIsQuarterly] = useState(true);
    const [isSol, setIsSol] = useState(true);
    const [solPrice, setSolPrice] = useState(null);
    const [overlayOffset, setOverlayOffset] = useState('20vw');

    useEffect(() => {
        (async function getSolPrice() {
            try {
                const res = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd');

                const { solana: { usd } } = await res.json();

                setSolPrice(Number(usd))
            } catch (err) {console.log(err)}
        })();
    }, []);

    const handlePricingChange = (event) => {
        setIsQuarterly(event.target.checked);
    }

    const handleCurrencyChange = (event) => {
        setIsSol(event.target.checked);
    }

    const pricingCells = useMemo(() => {
        let priceList = isQuarterly ? pricing.quarterly : pricing.monthly;
        let currency = 'USD';

        if (isSol && solPrice) {
            priceList = priceList.map(price => Math.round((price / solPrice) * 100) / 100);
            currency = 'SOL';
        }

        return (
            <>
                {priceList.map(price => <td key={price}>{price} {currency}</td>)}
            </>
        )
    }, [isQuarterly, isSol, solPrice]);

    return (
        <>
            <section className="pass-section">
                <h1>Lifetime access</h1>
                <div className="pass-types">
                    <div>
                        <h5>Valhalla Pass</h5>
                        <span className="description">489 Exist</span>
                        <img src={silverPass} alt="" />
                        <span className="description">lifetime access</span>
                            <a href="https://magiceden.io/marketplace/valhalla_pass?attributes=%7B%22Pass%22%3A%5B%22Silver%22%5D%7D&status=%5B%22all%22%5D" target="_blank" rel="noopner noreferrer">
                                <button className="primary">Get now</button>
                            </a>                    
                        </div>
                    <div>
                        <h5>Valhalla Golden Pass</h5>
                        <span className="description">10 Exist</span>
                        <img src={goldPass} alt="" />
                        <span className="description">lifetime access</span>
                        <a href="https://magiceden.io/marketplace/valhalla_pass?attributes=%7B%22Pass%22%3A%5B%22Gold%22%5D%7D&status=%5B%22all%22%5D" target="_blank" rel="noopner noreferrer">
                            <button className="primary">Get now</button>
                        </a>
                    </div>
                    <div>
                        <h5>Valhalla Platinum Pass</h5>
                        <span className="description">1 Exists</span>
                        <img src={platinumPass} alt="" />
                        <span className="description">lifetime access</span>
                        <a href="https://magiceden.io/item-details/6yuArtKbqZUEVjSxHA9uUTjAPnBfoXw6aaGXivtAK72T?name=Valhalla-Platinum-Pass-%23499" target="_blank" rel="noopner noreferrer">
                            <button className="primary">Get now</button>
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <h1>Pricing Plans</h1>
                <h3>Monthly Subscribtion</h3>
                <span className="description mt-24">Early bird registration opens on October 21</span>
                <div className="pricing-table">
                    <div className="controls">
                        <div className="pricing-mode">
                            <span>Monthly</span>
                            <label className="switch">
                                <input type="checkbox" onChange={handlePricingChange} checked={isQuarterly} />
                                <span className="slider"></span>
                            </label>
                            <span>Quarterly</span>
                        </div>
                        <div className="pricing-mode">
                            <span>$</span>
                            <label className="switch">
                                <input type="checkbox" onChange={handleCurrencyChange} checked={isSol} />
                                <span className="slider"></span>
                            </label>
                            <img src={solana} alt="" height={18} />
                        </div>
                    </div>
                    <table className="feature-table" cellSpacing={0}>
                        <div className="overlay" style={{ right: overlayOffset }}></div>
                        <thead>
                            <tr>
                                <td></td>
                                <td onClick={() => setOverlayOffset('40vw')}>Basic</td>
                                <td onClick={() => setOverlayOffset('20vw')}>Pro</td>
                                <td onClick={() => setOverlayOffset(0)}>Premium</td>
                            </tr>
                        </thead>
                        <tbody>
                            {features.map(({ title, basic, pro, premium}) => (
                                <tr key={title}>
                                    <td>{title}</td>
                                    <td>{basic}</td>
                                    <td>{pro}</td>
                                    <td>{premium}</td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                {pricingCells}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};

export default Pricing;
