import { Link } from 'react-router-dom';

import graphs from '../resources/assets/graphs.png';
import mainGraph from '../resources/assets/main_graph.mp4';
import iphone from '../resources/assets/iphone.png';
import macbook from '../resources/assets/macbook.png';
import chart from '../resources/assets/chart.mp4';
import advisor from '../resources/assets/advisor.png';
import markets from '../resources/assets/markets.png';
import pass from '../resources/assets/pass.png';

import './Homepage.scss';

export const Homepage = () => (
    <>
      <div className="landing">
        <div>
          <h1>Welcome to valhallapass.ai</h1>
          <span className="description">
            The only pass you need to get all necessary information and tools to succeed on the most popular blockchains. Follow the link below to get Lifetime access to valhallapass.ai
          </span>
          <a href="https://magiceden.io/marketplace/valhalla_pass?status=%5B%22all%22%5D" target="_blank" rel="noopner noreferrer">
            <button className="primary">
              Get pass
            </button>
          </a>
        </div>
        <div className="graphs">
          <div className="video-wrapper">
            <video src={mainGraph} autoPlay muted />
          </div>
          <img src={graphs} alt="" />
        </div>
        <div className="top-cross-section" />
      </div>
      <section className="analytics">
        <h1>Market Analytics</h1>
        <span className="description">
          The real time changing dashboard showing all on-chain information about the NFT market. Add your bookmarked filter of indicators for long-term use. Customize the dashboard as you want. Crypto markets will be available in early Q1 of 2023.
        </span>
        <div>
          <img src={macbook} alt="" />
          <img src={iphone} alt="" />
        </div>
      </section>
      <section className="features">
        <h1>Key Features</h1>
        <div>
          <div className="feature">
            <div>
              <h2>AI Bot Trader</h2>
              <span className="description">
                Our AI Bot never sleeps, raving your preferred blockchain, looking for Buying/Selling opportunities. Built in user-friendly UI will help you to customize AI Bot in your preferred way.
              </span>
            </div>
            <div className="chart-video">
              <video src={chart} autoPlay loop muted />
            </div>
          </div>
          <div className="feature">
            <img src={advisor} alt="" />
            <div>
              <h2>AI Advisor</h2>
              <span className="description">
                Almost the same thing as an AI Bot trader except Suggester Bot will never make a decision for you. Only easy-to-read signals based on historical data of the specific blockchain.
              </span>
            </div>
          </div>
          <div className="feature">
            <div>
              <h2>Watchlist</h2>
              <span className="description">
                Bookmark and track your favorite NFT collections. Easy-to-use filter-enabled bot will notify any changes regardless on specific asset.
              </span>
            </div>
            <img src={markets} alt="" />
          </div>
        </div>
        <Link to="/features">
          <button className="primary">
            <h3>Explore Features</h3>
          </button>
        </Link>
      </section>
      <section className="pass">
        <h1>Valhalla Pass NFT</h1>
        <span className="description">
          Collection of 500 Valhalla Pass NFTs consisting of 1 Diamond Valhalla pass, 10 Golden Valhalla passes and 489 Valhalla passes.
        </span>
        <div>
          <img src={pass} alt="" />
          <span className="description">
            Valhalla pass is the ticket you need to reserve the spot around the table of Valhalla. Boost your trading experience with us. 
            <br /><br />
            Get Lifetime access to amazing features that will help you through your trading journey. Only 500 Passes will ever exist. And only limited subscriptions will be available in the near future.
            <br /><br />
            There are 3 types of passes, all of them granting to its owner Lifetime access to valhallapass.ai
            <br /><br />
            Read the difference between passes here.
            <br /><br />
            Valhalla awaits!
          </span>
        </div>
      </section>
    </>
);

export default Homepage;