import { useEffect, useMemo, useState, useRef } from "react";
import { Routes, Route, NavLink, Link, useLocation } from "react-router-dom";
import Modal from "react-modal";
import isMobile from "is-mobile";
import emailjs from '@emailjs/browser';

import logo from "./resources/assets/logo.svg";
import bgShape1 from "./resources/assets/bg-shape-1.svg";
import bgShape2 from "./resources/assets/bg-shape-2.svg";
import logopass from "./resources/assets/silver_pass.png";
import magiceden from "./resources/assets/magiceden.svg";
import twitter from "./resources/assets/twitter.svg";
import discord from "./resources/assets/discord.svg";

import Homepage from './Homepage';
import Careers from './Careers';
import Features from './Features';
import Pricing from './Pricing';
import FAQ from './FAQ';
import ContactUs from "./ContactUs";

import "./App.scss";

// Must be taken from Kirakosyan or Movsisyan
const NFTTokensAdresses = [
  "9zS2adV3RtS61RvHSkZUWc8nK75Lat76iXmLzsEySH3n",
  "G5MsHnBJ3urqHdr2praTZqVbnhGC5HoJuFgXJLsW8wn7",
  "2osBPWCjhCXPy9kznypMAu8H1drhNKQfGPQjqLdQZ5HH",
  "3G7kyhMCNxCaaQateBfviUfzvPeaDhvrpsSRL5gPef1s",
  "EiQgNXPxtAvfgrSapFXerjs1kqrSdfAr2ZUGMAe27Qgc",
  "3dkQTRYtW7gMsvVJ3UGor9RZYpecJ8pquV3Qe3LNUJ64",
  "7hUaeUhpENPRT7FBbWxq7F25hwBC3776zS1xSg5HnD62",
  "49MXp6erAkd3aLcviemQAU11FQeRf8f8TUfQrA4cgpnw",
  "4JXMxJ6ATmVkHpkGzB7LPmBaz4KmYbBDXaaedcHorCsB",
  "CCLmLqfFgzwSvzAvL39XFXSBZUWoU8BVuWKnb4fpKbsK",
  "6g4MNMV1inJR598qLbBwCo73vfjURLLVCUs7RByxQ48J",
  "9vFsnqutexhg9VgYLmWVo1rgGdJ67AeZS5YXGY14pFoF",
  "FT3yTNeTHRHWmBfptuuCxS7bfqxw3VoJHoLLzGM29QmS",
  "FMFSagZUXanC5rhvfW9kbcZSmV1MUqCQjVWYBBHFFDX6",
  "2HGEnZexkgmb9gefC7D9xVE5a2kSJYxo261DuUTJkxmn",
  "3LoTCugSZMFcWv3XSyYGkwhNm996of3RZLNJSss4ECre",
  "CiVY1cKxerZtmsbQ2Ni8QLwN6dWwXv2T2H9GzAif9joB",
  "E5LXwdQdod6bmrUBAvVAfVjqHdJHT5xqbtXj1vJjEaGP",
  "2MnNd7i3THSQo5T5ntQXsyPiiYEyY7vXKc5TvsHdxy4r",
  "GrYXUcjbqDmE7yMSB8ofBF7EUU6UGFaL7HaHf7RYUxSH",
  "HnyHQxUujL3KLyt3HAvFawzDZYDFwJxJL8uJyqTc6GeF",
  "9YkkmJvFeYc3oVHWGie4eKXz2jfyN1h5EaFGHJ3bTDVo",
  "8hh98wAhfdQ2eWzHcGEwdNPWrk97CNDfz6p4p2EjNoAi",
  "GWGm4KJcKoq7jS4Fnz8NpqJ3LkEpXDbDVJskELaASeBn",
  "7vQQWqNe6WHpTUhuCunJdHqocvofxomFor1ePP6bB9hB",
  "Fgq57r8a7EiBj3ANWGqqsDVwJA2pwVGUoYPLmBCFcN2Z",
  "6jaie4cQ69j31rJQaikQ8Tk4iuvHKFz8JHrcoTno1foD",
  "7aBMrV3KQvVcK9fw5ZcMBPirLntho66AFQCWsi7nBG1w",
  "GHYwvreGMvJ77xrXhN4iLMbiaeY2AsAEnxc9V3xvvVJX",
  "bnsyaemdGMKycFUdXJNbAxFvQtChwVJuFBuDSY17j8U",
  "HHXgLpWFZ93fZcWS1xCEYMs8MvbNDLvawKxLECtZ2boe",
  "9RvyMqJwPJT7ewTqv5CLmp8sGQ3xUjkFDEhToCg7Dnfk",
  "8kmYFJzEnKwC3PBbLaJmhngaCpQy9hNLLymmVjq82aKx",
  "37eFpEpZUDuLu7vHLvJo3Xu3uowzu9XE4sNvgDgnE83f",
  "7a9TgYphhbyZcoCNzhAgbDBCzgFSrMd8Maktx6Nvtmjp",
  "FeAENkRpCrnMWiJb8WUDam1BYoSibZWLuw1JMmgctMyU",
  "Gc7QGoCA2q3jr5UhQKKSTEJM7CDMj9KbXngiGW8thuas",
  "7WsDr8fXX87k6xn5qukzF7DEPBmpCstJz6ZwhyyRRMao",
  "8fq6YkxMV3KY5vjS4QWbFKUT8WtZguedTVtBYwvHZQM1",
  "CH8AosqsdXzkS611PbTzcwR83z3LDxvuAJev7PdbkMu",
  "28TxnD8B28muz2FA7cF44rwnooaEaGt46U7gdD33R41T",
  "58CHs9R4mQzYN1aRdLu1KJWLz75xbTNvMLr2QhJVURbv",
  "8jnyJC239UUXWDXhxiBmF74W8RUVr7jvniiLCDwUf8oD",
  "AmQiyzL3Qddsc5mu93d1HPZAB6zcebbmvrAjUPf9ghoV",
  "Ds8BkK8jLKHFNhzjiBbZaWJ9scWuqKPfYtQSM64Xq9FS",
  "7UD4evy1zCGY48dEgapLFSgdAve8qMKXwVM1u64gpKD1",
  "BLeBjPWAgzdmupLy7mAgseXnJyG3yWcq2365ToJFdogy",
  "7ssTxPavxGezTooZjqFRRiMdB43LYAqGA5Z9n6dMZUcX",
  "E9HaGvA7XDktNRxUYVbdRDVC1DLG7ngNh9i6CqXh7W9C",
  "FoYurLUA6o6B8xpXKFnQTVuxQ3A4yQQXLmRaXN1GKTvE",
  "BGEapgdAm8aCz1QVbN8WqxpLiehWuaEmLNfaFXifuveG",
  "ESxTHtKqcBfsvnPRgLNBMw81DzPSKMmvk6pSuxpWSzZS",
  "8FvNPAmnFdzCZaE6jVMrMPiDFk58MEdAWwhkhK3J6udz",
  "ByTBKh4BHxYDM3U7MZSmYSBV8GpY6Ds1iE7q35mbWxQJ",
  "GNdzmhRyYwc9nLEbaYYaB1k7iDqMyRU54LgctAig9DUR",
  "EqWycNNEmrnD4rzw3nqvDi1iAseqdcLxJo7eny5jgsvJ",
  "7JginoeA2VzriH152jPEF9VXuExCPW82wGMart7mcfZg",
  "C4w96cwv8Nd95SjfazbjjoTVHxiwskoPeFmgmKke8iFb",
  "4xSFyKZJDhHYWaJxkhMw7RCuLaXWhkS1F8yH5JAuWov5",
  "2cxLkGzWqFmTs35jhn3SKaPpoNKAkvmiadEfC1Xm61yv",
  "J8t3FANRivarPVL6hkkzCSZ1qR3YT5yDwytjG2NMXxbQ",
  "EVnEMvjS27f7PLmG2AC6sZbj1Ur3Xww93KYLDjAhdE7R",
  "DxJgTZtPp7tANVBMbtJLpdoFF9VWYdRkBf6QCnsepDs6",
  "YYv8zYEiox2UJpDvYDZPrVs8XQBpEFycztZy1GQyB87",
  "HfkUm7WZAxn6FRgbyNEx5oEDDByuf1HSig4JDRAJf88F",
  "GpFCEZDn99v9MLVwzfwKpo3KUAcDWgtuo5RsbcjK5kLa",
  "GrCGJPjdFHc8Zg7BqMvo8dgfRboqqLee2Gas2gUygUy3",
  "7fbUDo694ogCZ3Ny1YJiM2qnz3EUwnbiBBF4sQr46Lhw",
  "2ZsxtnfwDKKRFKh9E1LDUmD3ZcP2qyGGpbb2bZWWUcvR",
  "BWthkxS57BnyEVdNC5ogSZBbtxRHj4CczN4XHYGvbgXp",
  "GHSAVajacnQ6WctYMv6YwZvQmy9y8gENopzz2azjDZcw",
  "CVsnkdTAh5CnTmXZvHMTEsFhadHjJ51mMSV6EH1r4CUE",
  "8bkA63CxvFDzKyAVV7xbU3ZuEf5maLbE7QCqAEF3zR7e",
  "HjLesbmEvCyvr1TyN21X1j21ojnktX6aHuD7yLQ8yAKA",
  "GMPt8cwiWwFzbkwxfZNoqdLAoSBKkeSNPVw2RD7XUtWa",
  "HcwgUHAUcDVjmDdanGyhPkR4tKfSEMbHhSc4d5GbiQmS",
  "FJBmQb4FdXwDxCCF6Qjb9G2i4ZfxANW7Uz6BvgqPZsfz",
  "3ExdGXMcvJiZreBfGuG84jvQWvxUqwo9LWYdHQre8YZs",
  "AaPrVWNoG597CVZbFLwTJwo9YdM9LvFcDtxJnp6tHvQE",
  "v9SBbx7fH5dU3yoc8ZT3Bre7NMLLg8YVrpCSLLMLLhw",
  "DqKTn7yMLEXgZxtyV5h6NZrekLCExQZ3Pe1464MZkNW3",
  "83RW36aew2YrJgX6DrYr5CnctQdo5Q3TAK2VKhBksSCS",
  "C2AZ4YRMVwpMNUG2Wi5V8XPBodno6mP5ot6u2mvq1pbh",
  "BMdBdoTtnWX72qpJbWHJWKBTvQF2VskA6TNEfknMhyjx",
  "DrPPrJQTQ18ogiP97WW9BkVV8p7D7kNBHDEPbUMv6LVd",
  "4ca727nMaK32wJZtpCL1hrdE3oU47oYVyf5zxhqeq6iV",
  "8e2wAKMTrAy5xPxq9PLsCDTuNH51fCtaFejSi1UqS8Gt",
  "2mb1UeMdv5CLK5hb7tZo9n6VSjad4reQkknCiMqsu1um",
  "99xxNoC9nXwmG8zgmaDEzVCwPusNLKsAcnYe1G9AE7im",
  "8AcgkwaTsshVRUBub6vyerSb3ueBDdL7KHsbbKuYxn8g",
  "8Vy2QB9bw3xdhQMpL5QcjHedPQDn4h4pKbZK4BKT7M1i",
  "BdZ95BqfCegQfNKKC2fLX8U7Z6FTkkTw81KXRr8fBV71",
  "G3dpSk4zh4u7Lh141y3SAXWYs2mFvhya68rWdrSeTakW",
  "GPAhESaAYmztmqefWvCaHtikJfsGd1xubwZURc22cdRT",
  "FZs3EKTB4X36dt3MsneKh3SV6k3dvvdSrGb5t5Nsbe6f",
  "HYo1MvjzMjaXUU6VgTF6wHhyQtqYVpKVYZFVnpV4HrNB",
  "4ySohnCZ8G3JB4fGXbGKy4tzpxMcrD9Wy4P6nPQPmw6G",
  "5RVUn36MS4kxSh4hNMqq6guUn9yQmAiGeD3FPsssnE75",
  "FPE3iXjyyvetwqtRQNzHCHPhEfmSy8Hd7XFWR3Thy2wD",
  "J1Y7AjY74jMcc4dAfwNgVsyYY1WBAR384CjFWUi1QM8V",
  "DBx8RRAcb2TJa71U6Yx9rGKaEWZkDJ3JNhXDDPBefScw",
  "AbvS4bHnozVmHv7MU4yvdu2xigggErQxpNkF8QvDCBDr",
  "6jBoLenmwfb4on1jN1mTaFfo5pnVssNCyx7ZKHHowEe2",
  "AZAVh82HJtYFdrk54kQCMHemHTqfke8QWYTWrShv862t",
  "HAUPEioMnzqjxmzhTExS5CZuC4Wf4veCZRKMSBTX2rgy",
  "7DBdhaCSGUQAJbAwnUbBNnpVeYnruJtwXfdyS6Dsrdrn",
  "8qrFGJBB4oktWrz1u6sQChitwNdvt19LLLyoxDv1qdfS",
  "37VViY3jmmc44wXEF8qFkWvcCxcpePNTh7S97efH8GWv",
  "86NKk3TSiXR6sQSWYisMZbTz58uTcAeJh5Fta683bsgD",
  "Bcmksub75AWK78qJLA9yLuxmHo8er9XhaTwK3KPxa7Uq",
  "7eVPpVX7z8fXuzkn1P5LhNyEMFXuGEpLTbEu6z1Xg3UC",
  "D9FzAe4vyn869uhTDAGCGjcU5F9xPgjm9MqBfWPz8ZT6",
  "GHXXpn7UZ4RLN1JeD4ySRx4xWZzQGfg8RWTo9zyg4jWz",
  "ExSRaWkYqmCJvPivQ4C2qnnZePurRzdJmteZeyByMY7a",
  "7frGYxY9Cw4tjnW1ut6AAh3GFfMDYJ3WDxcfevvbD6yc",
  "8biEZcHr5rPmECPn5SLUUf6WxzKTarmsuEKoAKjJNgJm",
  "F1Nks26jNj55xfH5o529apLfv72M3kknRcXBtpM3ZDMU",
  "5dMFspwFt2RQ6BwohRD4u3cTEi3CRGcrLEEbHLPGP13x",
  "4NJQGYXjMoHk8zhiE8z7VLqdp1M5Uxz2EermZn3tjaS4",
  "DqZ1AHZ8DrSumqeWcLGr32yMTnUtirBiaeKCfL2FyAwy",
  "DfSVFxwjhGrwoRzNDBG1W6HbQycSrb97G3DCNdTUZFdZ",
  "5LuSu5u99N4CcotjengpTFvCsUxUfTz8nqYRAypiArXa",
  "F6r24eZiExwpd3hNKaVsVF94EmfQPxbSFu4CiLxeTsfv",
  "HQtELp5Q8GTN2WBGKFELhkdW83K5DF8S9JF66zUrsHRX",
  "3t7AmH5zkzho28qwgN2UESUVspk3mnvCjW72iReQpPJY",
  "HYPjQG8LD35znXLxnbqsMGyRM8ssfTkmZ8EJ63Y8jDLk",
  "8sby9hsnNZckXMJk6pLhrXaggNLBgRwve3DxLWp3BqS2",
  "AKnyR8ng7JAm8EniHeZcWqwpHw2bHM9kLUW8vCJ5ykWu",
  "FMPCAaaeGHKcDmo6CqqYXdARt43UncoEQvUPEYFP6YM3",
  "BGMYcX4XqQ79QifGpRmyXPMpjmoqpfTAzc64Z5QWFran",
  "ANrz3hgKzu1SpSc4SBy5XK5tjgikxsBKsGzTEehbR3GD",
  "FKhbDJJnW2ZwcD34D8YwEu2aXiAchJ6KxJ96YyqFVM3s",
  "2NE3fs1wL8mKETFH2E9sCPVQqA7eMmEScik9MRbDtgZ3",
  "4Jrf3x9gzVUNChzMDAfZh7wQo8aVdtir75TiatZZ78B6",
  "86jrn6EkF9bqBJ8NCjJp4DVAZHEDrR4LBvNDkwuZezu2",
  "AweHTYzMAmFrp9rCywfTAM76XMdugHYZQGE7zc2aC7TM",
  "25X3zzi1waoPLTfppP4gNgx2vZgF5HKR8PGF3HETr5Eq",
  "35HFyMCvqhMWacVD9MGmLKyiFtDYLSBNGZr9cLwsGepP",
  "7bQUNqq4CKzrKcdCyWsgKuREWSDZH8r7ZBf38hC4tFbH",
  "HeMHXVGhUU5nEJ5mWVa9jBmwgfgy4BYXgudYAaSYpFCA",
  "7uLVcW14zAE3GerSyovjVfmpuYEqhF5DoMZonwCLr75R",
  "4riBV8N8XQcuXFD1qCXMh3E5CQX1uSeeDCmhLhFSSS8K",
  "oXMtHVvAfsBgKznszpEnYJP5yso7ALHnX2j5swpmnGQ",
  "2RbySt5RGQXBShJ3vosxoE3dPEhju7xdnzjytfrSGLg4",
  "Effjp5XtcQAeKqdd7voPDWmAyG7wyEmXDWmZj4q9BJNs",
  "DZQkEptGu36RK7sJVnp6mxsk6f1g5PJLHbaEhCcxoUGj",
  "51unUiYXpUVCusSHWXk6Dvu8JRUEVW8CWpMoBcEygCSa",
  "GuCsEG4YCeyfgLmziqn29KJtkSP1BdyQXKx3hQ8cevZu",
  "B6FTGr8qXhsWHLsEs62bsPWa2RFzJhX1MPKUhs1w7Saa",
  "4zwdZ4Wx2iUWo4PgK4LokExawP6fDzX5fHFN8SB9J4Ci",
  "86TrPjWYTS97f2RRN5RZf12jooFYwAjYMMMsnwPLnuaE",
  "3XL779wUmVigYHXs6QFYFNZFwWHRctF7RDJgg73eq3pQ",
  "8yX36aBdxkvVKB2GFqPMkAQcLuLWziQdEPCVVfCxDqu7",
  "8v7XiiS7H2nzLpf73AZUsYewr3iaDVVGeN7Dp6BXoxEf",
  "Bkg63Xwe9T6JjepEBvriNCHXJPZmFXguTXY6xU4keKXJ",
  "57G8aoZ36tSKyeQfBzTXtYytMxJtefNuqpw7onTBBENV",
  "5h6o1FMnQj8aSaxgorHac7MdKUymWJ2eUdNZpFbVfGk4",
  "H9gJf2PH1qzez8BEa1euXGEDPmCYJjvYo6GGPBGC7YJg",
  "CJ2qR1A61a7L25siZb3QM4SVdZxGh6ajiuNsGvhN9xk3",
  "Hprsn1GNwdnwdZjYZdv77ruuS3ff7VjtDumQLreBtb6L",
  "EzvMxL1WEbRy3cELQUSZZ9m6iA92Xg1SAn7GpanCLu1G",
  "CAvjVTQTKRJkVcV73UvuHKcWdXnXsnLeXapX2qQXt8vz",
  "E2AHei1dCporWz2WwM8JKcz3SKfqNo3VEoFSo9TS7g2x",
  "DgrZKSwzCZnNmnj6Hani9GvKBB7Jfg46rMqDTfsq7qwg",
  "AE2ccAp3HWURxfaXQs1RbeFM7wLr5v9gWDwvENEKCQjU",
  "7B4jAbn3bTEwk43YikKsX7hJs2GcfQhc5qXhFMGtvHjP",
  "AkJNmJAvuFXkj7kqXBnnGa7diYqrxoq9tL3Cw5pn26hN",
  "7bHovk9ZivAFQEeBStQNgRtjPNPxmRM6nnAUTr8spdC1",
  "oAxbEigFarWTe3psLMUN8pSMFK5wXMUWSp7kVprUmse",
  "AUQXF5UoDQHJdCgnVNPhG3o1ULoVG5BwFjNwZRki7368",
  "G7a1mDPnMsgqkjFma1QyrkbqmbKMyGoJj2miuV8SBoD7",
  "Hrirah99KtGHfifWTxexmRhfu1bx5Q3LWKWZmFe6sBNu",
  "6k1p2sCNNmdYhdzyVxq2y28PU81eeJzrMEmPe1P1Zzsu",
  "6Q9pWHDPmv8LVRQwT8ALFSc1Nzhewtr9mHvt4jtypKKv",
  "BkKxFuKoWz1XZeUibjLZmutF7oABgB42uhoXL7AKRMQk",
  "FRUhzrsrzav6ne1caXnEPaP8guM9gdsfdsGhegFXwQfH",
  "SwRdaSSLujUgSk4NjayQk4PzmW9jMtmhT3UVdCpkFSX",
  "GKJVsQUtTAQmSQcW1SMcfwPCKiwPjNfT5hswWkJDJZXJ",
  "2nhubabEdpgq4MzD8d7W6oQT5TuMdDKXdZsXwEzWLm3a",
  "8s5eqdbvngTMVFB1FimUAhXxsPWTQaY9foqkERcC8tpA",
  "8vu17rauZt6EcNR9twJc7kUwhVq3E6ALqjiY4xEXZ3Nh",
  "XDPC94fT6RiiAHjbBHKUsoC5aLh3z1zJLdhMiwhkoQV",
  "Fk2BiLgg9cEYuoDqGHGyYdEtyAsUTmgizR31tw46jvkN",
  "BbDM1pzBncEcggrknWYUataqHmtq8C8C1MuGpP3dewGP",
  "CydQVMzdqvUBcbLqQ6J4pfV6ZCyF5dDnQp5ThfRLVYwE",
  "FPshsP9taUPiXsPnjqzhZC6mH6qZ4cLPDfhDSjifd9ta",
  "6rBKX4URtfr8EzqzfUJyBKFLRDaNX5TmjXSNoqHcLrof",
  "46MGzLJ8kF1PotDZYf1qzmjk5RL1M83NG81aPeq1u1po",
  "L52TqXQEkRxdi3XoB2HpsQt7ysWW1RrXAEBthaLrHkP",
  "6vJN2wWqsxvZzM1wViPmUYuAivZvd4RXpy8jqeFk1Uck",
  "A7Puy2eHhbPJLkauG2ZadwcqU84FBU8gcoAayfd2FGZv",
  "4pqjN1FThZkTFwvAg2vXS7PnpdCzdWurFpagoB3169zi",
  "12DgQm1p6iD6gQnPR5H5AYRGjd8GnzRvYd6bbdhTYzQp",
  "Fq92y2bFNRqKduHKAHs5cxFGjJTnafM8Mh7QbLefYnnG",
  "4PxPUDduMjGTDWiJXtmGbvL4HaZwW8pA4E1LHViM8AQb",
  "DnDonoJM3uTRM9XyKrsXRU7Dc9vYTQ2Pey1WP7oZBLi7",
  "B1iKhR5ty5uwXkeKjyhZJWNsP2SnBn3dSGLZVUqGQiQP",
  "49ASz7QuiFniDcG4w2eRnAXvXpJTQd1AvXnD2cv2mWHz",
  "DPoJueqTYvhJia1SFYYHxukDeTW2pdZVk47zGXkTw1tS",
  "WkL2BNWxPR8yiNMXBKrjjAtjfxRjLxS1qnXCjuqm7cm",
  "5BaRhTwD4Mem8mCPDVkpc3viU8r38WeirpfjBvGTD8vt",
  "G1XqRER2zsdBhhoDYnFnKxBFNM2oB3Lnpw6L3gSvh6dF",
  "6maKTkPZjV1t7AEb8GpbVaTnLHCqg3X848cPT2tU8Si9",
  "8dy4UiWYB3zBn6o5tfAwXf6uq9s29DkFt5XE6mGbpLAd",
  "7Aqsi8nZzqYGR9m46uPGmYYnadvGBLwSATuAsgfASubK",
  "91ANy4LVJzrWtcUCVNacpGva3H5R9MPprgphW5pkDdDB",
  "CGnmJZMCxVmeeBKTbm4xaJo1rTjZ4FQFagaR9bqGkwFH",
  "2yfGYZEiyes6eFg5ckGt6HENuateJkBnTXmscHfLYk3H",
  "5f4gzhTweDsM7wPFXAoDYttZjUWESzGdnMBvHvJTYE43",
  "DoASx6M8dJJ6N2nZaEctxyiH8sMu4VbJR7ftgSAbppzS",
  "J1AGeUStkSzxM8HMvNAQoU5GSV7uFZRPXZTNtXgbEbdB",
  "7XpcdSJS5a6wYEnjzjf278cha5iE4wVpB1WDovwqez8e",
  "FPQ59hP7wBo2SCVw1cxctBc6P3i5HVVMZ5SQ14xetAte",
  "Ff256UvF1P52J9nJE9WqjL981CUwj5tfrr46HxDKWeax",
  "2hMm3TFqEaREsZmvYnJzwFhQ1Mf8HXycCQatAQPF4hid",
  "83UmiWN8bfv4VUGP3w12k3aaaHb9yDTjmtjBrzqLxF3W",
  "6DkJ3RCrxyqhDjA1YyQewGryZShRQ1ZycKZzptxpsMZ3",
  "CdxEe9zaCwgoNt7M3KJRmHo7xMDnh8EaqG29WWzyoFcN",
  "3cbcWsLM4ecBpMrqn3dmJ9hZDaiE1NSSaRWo3NyBXqYA",
  "F4px18WBk5jKGHKaQMkSujXEAXvs2TsVGyhUciEhftcY",
  "HaNASbNdUx9YaJY1yTFDpACSn69mZvfLr7gkh6vbi4gm",
  "2zHQzbf1gAmnRjdzhNPZuguz6faUsS7kCmtXVjMphZ8d",
  "HXjbBYRmfjze49Fx7cm7HK1Jw91iEGkHowyWeRo6hSDY",
  "Gtfx79jtQBhiN5bZCjPMwC9mRWGZaE96Xu4mdpp1TF5e",
  "3SV6EkamtN9tGjesAg9TxCErPiU5MPZm4c7uLjhrCEFq",
  "5CHZrS33Hsod8JcP6koPL3nLcHTeDZ8b8uoTpVtMvm9h",
  "ESauidF4du68XYqN941EQV4NePhpsbWTuS5Tiw7TNoEC",
  "8eAoL48NgqoD9NRZXdb8A2XjMfJvemugWBPLoyHaGYNM",
  "6uEgMh5wqgYRYojhhPqrrHDm9qyxPtsh3HkD6rvr54k",
  "DhKuM63HsD5RUAMFqfAoE5ujkVNmsLgtv4KZNx18ZrZB",
  "636mNstsjeMZ4MvHnKamN43wnek8P7qZRE6BiZ1FRQvJ",
  "FcEGKA6nMQHg1GXbnWBVicBMeG6LPC6gzyVvajaj36Mq",
  "cN4EumK5jXyDESLdur32p9t9B9efjfPpBFaWxSbaenG",
  "6ofyN6ceEFVM1qcP9rESZ7kvr3CKqeVQcqmZDjK2K5rB",
  "2P2bBJE3NsMDaK2KAVFENkWub1gQ8o16VUVtTdo3SefP",
  "BVM7GtYEq2Zbf17JH3arJWNRztB3FzNzFxBtTkGahM6Q",
  "DVnrothV9B9DLaHwDrBkGwwJoPPm95f8MixhpT4yYTy8",
  "7XL7Nfi7Ei4J2Qexhacu1y2ntYA9D6ZjwWETC87q6fjr",
  "N4cj3PE4GeeVa1wwWvqFDFUR86icj24ksXRdwcJHdFY",
  "5xXg3RGqA5SMBfD5UL284EExbSmkeRkMj14YBCxF5LVg",
  "7eCsEjfgivR29ViYpnph9YaPMWDbcyQfLSVZ9BYVxMwU",
  "8ZzSaAjSb689UFUEaVN9pnTxkwbBpfDHpR241tGeysKK",
  "FWzidDE7AX23za4XMDUk54WJPrLynnWw72xYdhRa8SFK",
  "Ck7NBfk6nQAxSSWvBv7LY9fBet6dVNuxiFpQwYhhRsLA",
  "BELWs16wodR4fqs8NhSTqSEDfJ9Xn9yaBPKaHGdWoqNQ",
  "BuU5NemDs4rThQ4dMTGATdHZXKBRVkXzHs7TmY5jV9QX",
  "3HSoF5sB844842tfmzHXU5EHJqiDz2UnpzCjWGWyJF1W",
  "BQrexR1uEacER9fgqiipAbhmF5at5843BVZ2KqqNFEM1",
  "3CEJRCgzx95Xs6XTmvafnCvXBCe9eQyHyKvktX1SzCNJ",
  "ABnpH6U815JrZWbUuTiYqpA57tC7ADqvjG4tguBPqK8h",
  "G9Eqd4MQuKLymWrUocePF3wZjsCWdk6CsY19PTtVqUCW",
  "3UWrVywXsV2f81ghjEk1ZzGZSGecJKZ3HF9TcfX4wZsv",
  "Hq1y2cwbawiSH7v1TShSQrMpnGpgWn8x8eoJLY3caj6q",
  "4MmbdyomyjrYmD6tBR4A8BL5mtjE9QNQbnNkCSTEBAPJ",
  "DmitctEhHftzhTMbgrrGz8rhvYmTMTbtzTn8Ki8TDeXQ",
  "GtsJQx7w57xwvdaNDQKKu5D8QLnhQW2dmYLR3Zhmd5hp",
  "foep5QBZMdKWWfe3pbWTWkyY6XE4xR7LyUovmBGY15w",
  "H1BS44J13S78jGRAwoAisNV71Vk3fBXm2bX1hvYJxLzq",
  "84o7riSg9h4WHdau1SNjVr9Ke2fD6X2ER3BrWrtuPL6z",
  "AvLp1pDfn6d2ouKBn2eRtoyA8zAS4FFo56RFC6fbhKhH",
  "FcZUXqAG1sAXnF2NMLrjjb9iiVL9RiR4TQE124whGidL",
  "HtUyRBsZfEwBm27r4P75AFoZL3pZon4CR3XGLmebrE5N",
  "4244TFW5tQDxDYNzzK4kYb7RWuzxcgNUEuuVqXHF2EEz",
  "2JyT4rJAuZa1t9ajTuDhj3MnfjeCX8at8y2EgR49nHPQ",
  "BRxafh7VJyBYShb9SoyEGq4KoZ2QaoVUmWpwHu1nB2sd",
  "8KYgFVoVRQkZfsr3Ku5Ay8pGiezJBoZWvBwSGKskkHDG",
  "Cc7DK7PxUKxMB9wyyeduozWPTWKWo1LiCT3XTTz8bjs7",
  "KSqXUbRPiHF6QPUBbXRKbgDZB6GgQJVqE2RK9cqPdMV",
  "7G4aaqRKnAGNCn5bgmWMK7Uixhq9pef7S28Q22DSnARz",
  "GXJsds8uAKMiCm1pUcuUjJEKjmBg9bsL4biaeBs7C1Ar",
  "5xHW9yrUHAmaZn7jqY3EBH82n2V1Wk2kDMRN9Vfpof28",
  "HsgCpRX5fxJTDdhQpuyPy7MFgd53aejKZfZQtaEiwKs",
  "E6pqjweAxZQXJSrHijtcqHQG5NBDdgrN9gaGbHAnXZJQ",
  "6Ewry4a1mPZPeWPHFakprMbxXjKdTB3yW3TtEf3BFktK",
  "FjC6qgYCRPowzNSMt8SnLjoDUuXGqfzxiAVNqoFsh69t",
  "9TR7n1ky36uBbXLvxZAStwL4FTgkzVVJt6Lprj6Z7vSi",
  "J81hHuzYRzeSQhJr71UYRZwBN8EX36tPJbPTomguV6hD",
  "FWtz5h1JsvtVzqjdHiXVHzbh79Dv7vjJu8BbYpBrVBWr",
  "5ZKajDhDrmsmEEfYCc3HdZYmVNkbwt7hYMwcWs1N55W7",
  "CMjJuCXNwWAs9amfiwi6p69PfxS6ELga5Qz9qLc5U5G",
  "3F4GFWAMGt3a7HHCM5gPDd4QAZKUnfaMxqQRTS9HFbEt",
  "HBaR1rDWexqzJq11AVH6Qd4VSKZX448cwmtiZHg3fGNB",
  "BrXTzgNvaq34Zo2EmAriX4NZSnUigygNj3ZRgjwby9mY",
  "Ec4eSa9J5wGXbvdxSugPQmnig2TLS3BZZRBod1FTgb42",
  "BRFDUo49jhtbbkxZprr1fKV8HUbkkA1fcye9mjGeXKkz",
  "4fA7mZYDDtGV5Q9A65S8rGs6wtLCM2g1QUbMVhCFHJr7",
  "5VRenQGjyWsiQNw26Ve9BDTNX5bJNPwQNCKSgcB3JnVz",
  "GvsGRFHYCwGUMA3Wyg24v4fVfKAQdwFEWEKhpT8859ws",
  "FziteEVvyBSbY5YbPR1VDvR1FWWRWSVaLssKtYoZLYRY",
  "59wuSog9AwQUHdtUycG5PATqDdPC12vL56nHHozaZeSR",
  "E7W7GJeqjRvZtui8FK1UTJbU1KUhZAbhbnysTxtdANs9",
  "5hg6bLKxoae1MQ88Yi4cX5DVwmv7X1ERjyNivsJDBkvx",
  "HHgTcQrrvfcRYENSK5LFeSk7BS3zYjS6dk8V1Y16Xjm7",
  "3B8MyVC6fdC3UPvvhPwBsYRjg3U2DBKznmWxJrhBSpgf",
  "AMyxe52kts8V4kzXGEsAwr4PCz2pUh7ibkkqLn5Rp86L",
  "BAChGVA3iAeD5LEahK3DBbWT7YhSacqVvydA8HrBXBcr",
  "EyhjAR3DergZYis1KzV3rmAo5J5JcPztAxx31K7vvBdU",
  "4JybQqyobHuLzPjTd5bRyW45YA8K3yoaNyLwFNY134du",
  "4FKPu61L6844wXLcBDoc4k9WpWFfHLsfB3SgUvRuFiMB",
  "HPsnUVtiYQ6y8CaygimUBD5UuqaZAx4Tp7zKatt4L3pg",
  "HSThTQWn4osM7Lx3gq2W4q3tXU6n86g2uiPfQ3K8ZwPy",
  "6Qp8zgmKfnaJcXgMTJBvtJF4xF5vDcfu1FTzt6HoAU9j",
  "9Lu4HXdyYNZfJkNUcg5wkXTjAiV9Up65xKaGT5EjvuMQ",
  "4Wr2PZKo3ppD1rcT1sW2KWWPzNMxeQzjbaoQEE7uUHGC",
  "GDrrtcTJFjSQ7YuCVQZLS37KGEor6AXhmy4TVvajAoWv",
  "8JDceb7rjcZGbZwRoMijbkK1UftCJecrhjfJZwj14vtW",
  "7Nk5co7YmtRwSxSCV1uDxqRgX9aXzTSD9Xm5VWiJwK4e",
  "7UkWGfKW5Ct6iSxQMX5KGiLnSwveWeB6cGVxdqDztmTs",
  "5SALFuyo5ketgREGhT2Bo8GPbwmhxYauqVkKWQLMYuLy",
  "DNne368cXFXFMG3gWmQFJpoGhkSbR3L1XVwHhuS9s2VG",
  "14tcmNSx4Sd7MKYeR55UAaUYzFnWRvfEoFYEE51HMLBU",
  "7PUUhMWSiFt8GGj5ZHoUEwu2K8FTmnwcJGZBpwx1yjBH",
  "6P1T4kBx7DDGgKi3wNU4FBJTbjAzwivb3pETwMhQNoif",
  "HQgfLyAQhNQZhpXsrX2w39kGpGT7bHQVjUPb8o2KFM1X",
  "5EpM8bcAa1hVP68gEp7UxS7KDyHNivo9fN1skRgMUQoh",
  "BxBKRVVmW36VzKXVh66TDDRxrx7ron7KFTsrF1PhZxtg",
  "HWCeEf2qgURxiXCvAn9fJKSiiiVd6SW1WNdtZ4SoBjit",
  "BJVvDYzDPB7KVY1HF6kaGJgaWvAwczqxJhfZk99ruMAj",
  "EgWsqmNRDvLXTgedw6eSsm78eqtPSRzTvrQ6k7Bwyn37",
  "97Tkg2scMzwKAFz2iC8r7RGTFURqzhmTYaStdjWnFu6j",
  "BGWQCCBaQHQxzqJqvGCsD7q4a3zgB6Gx2rZEuLVFYdwN",
  "3gNLER7838gCtYWPGS3J5hyNnXaxa2dzh4YGz1mJMVzM",
  "5wJvqYvjTv4VLtFJy5jLHT9M7R2pcFzP5svoKTPtJw2p",
  "J7diyMxX8gMJHA7iiAQkKqHHqNo9QY17cB69v2Kts1Yc",
  "5DtXyLZnJfFSPoAELhingcv3366DEHnhfPEmZnjuAfZx",
  "54dtQDdwiuQtWp49aaLVEtnJ8cBV9MKXFZ7jgs2xUDWA",
  "9RUJsVgAS4DhTgPJ2v6LKkWxrGzsJr9BAPvwd558AMJB",
  "GvycJp2ArQFkjw7YdqYuNut4xFXgmbSjVeoxy7HjQEyx",
  "38cto9Dyh1vKrCp5qRtaRvxXuQTfab6KEqmm9fCCR9vw",
  "98LyG8yHNiSyYcLSGH54661wtTd1J42aV1ncMWp8cwXK",
  "BXneaM8Rmdk2YxCwFe8dcXJqwRanD7NxT9Hx6VHmaqzu",
  "DmEim7PU4Ccm62gjzhVnrpgQ7YEqEngqEXjfbVvApaQR",
  "4xLZnP5j8B6fFfBhJLK9g7uPPZTnsgTh3hQKfjkuyLPv",
  "Hu5KtqR9JpucoABKCnbwzFCSAA1QUgZrbwjNF9sway7m",
  "8tVDXVXJbFB2WGPwxnu5cFWHPLgtbYiGBidnDPqCc2Hr",
  "As7xDmWdSx8jdq2RmsJGPfT6uuXWJs9R9tNSRKfy7LVU",
  "J7SDXu9wa9h1Y6GR2KvLTKcm8ndv24hPLadwV2K4gnPE",
  "9z4BXPM5DW3U6zx2G79k4csVxj76jLJB5ioE5v8kx8vk",
  "CPDYx16JU8vqQUgeGsnyCqctv878JiMN6Z2E5ocQFt13",
  "48nwKFEkbYLhQ8HcSaYQYe4aGQ7SHoTbYzxrjqnvAdSG",
  "nHAL55LdUrRMP3MnQP8fzmAn4S1kiH2ncJoDpYyJCx2",
  "BvA5jkY3eFaim57ryQmPi6mHgqQSTGukgH375qdSb5mk",
  "Bae5CzpnLP6eEUoFy2jgFWZmderwXPZys2hgLJEBGPk",
  "3WDcAwsc56EQb9kpF4TcwBf6TgwMpbpxSWmEnTmAMFQ4",
  "GY7ofDFnbm4cvnFhGpUnTVhMbrkxnE3Keo6qbNdCE6MK",
  "GkvXmkY44c6rhMDFSVZorFaTGA88gxgnBhCRB2dW6sze",
  "BkLk6BHa75taqdhYWFV2uk9ETgRHFQNHvV5MdA5svNsR",
  "4Sa6wHyVr3S3PkjjtDnVgDPm4KkFFGdhDeC5yA38UsFE",
  "BWj7jNsagDTBbiva4ituYRC6QxNFZzVQnVvaNdbeLpsM",
  "GiN7B4JyaMRsH6skM1MJoczbnnbuSt6gQ64HzL6QfiZ6",
  "3JKQeCYZsfb8pAsrbkYVXtw6KYNkQCguchKDKs77k6h6",
  "8Q8ULo1QE6o8H42H5cCuREAdE3DXHiKwtKX5PLDFHWYn",
  "7BrSE55PPbhj5kXyvChivTSFJudBgfPgirC76M4Exsxd",
  "95cSLSsSbD9vLVvb9A2rHBzNqPuH8wQa15HAGvrF1SL3",
  "A4QpRhieNu7i8LoyTRF1KuTJVjLaaxGjgTthbhsQQhqb",
  "CzEQk6JcKbRNKU3D5w96id9beqvSfxtVxY5o6Ebe2A6Q",
  "4qjRhGHK9XWFotVwo4aCtZZVDmpV1RstMfQoKfamu9GN",
  "3NLBB62KVuzoFNTXt4MHnRbX9jaei8iYhiVRYkEfTb89",
  "E1rfXf8eddvLnpXCw7hGfZCxi2kEP37Ec4GGxxjjq7ZX",
  "FXsXsQ1VXZ38QrCjsotvwNELG4wEpbQUE6CP95yLxK8x",
  "2H3KeBiGMVgDFbEVYLVCy6mTBSmNhJ1Nx88kgiv1WyVR",
  "AcMD6gqmnnrSQ51dJ1S2mvomnUZw9pA6fCenwMDooo4E",
  "8Dwo4RkiUYAaYavH3v8y1bmUQywFoqk2wtqgGjPv7mko",
  "BKJgXunoxBDZ8KjVxNGWP6VN5A7SY4WTQWzyJLabRr4C",
  "9pYxhuiLjXmZ4DtRnb56f2Xv6hXbo8qWBE4bbToVoiF5",
  "EDw3CosPiJarete9B4xtxDD5zCWXSbYz7qDatn4ZbrJK",
  "FujskEN5LZuJG6FZuF935jTdxCUuDZ26wo7r7knLQAUj",
  "CHU8JuJLxkAcdtWkavP1mKjBdbBWPUoiEfL9jtRiNc1F",
  "Aoh2bzeDQ6mMkA2j3eH76pyJSfVXBD6B24tHUorSZ2o1",
  "4FbU6JHXkNviE9jgzR6tKwaavRVXjhqfuqhCVAkJzzFE",
  "5yQhLhYPG2PgD2bU8NFNZNAncYGa9cBADvV6T2qGN2qq",
  "742hsrhQWkpBHeYUfiYoDgDuNKz2DdQr8pueZmoQX6yZ",
  "3xZoufgZ2AicNDYXZ9Q6teoh1h5QJ97pK3S8v66qFa2k",
  "5ocaaBHmLEXAd1JQqo7fmMxBwRkzSGK9yiJ9q3mWv64S",
  "421seVKngtfrd4iYxBU7BDwGXxYSDxAxMKmCJjbee61j",
  "7R11rG1UwMcQKkPsJNYCGaGer3ZaKo3PrdFSp9gJFdZN",
  "EDk8EkYuAu2zQ4ZjBWCCYQVCtZk46e7Vskm54Lwc7f4Y",
  "Ey2DSy8se8KhgcCFzMP2jUQA62Vm7oydczDv4X6dtzmC",
  "4Zoi9kSMtLydfk5yXhijhYZbtD4S5qvBNE8hF1ZgfuZ1",
  "6AbsaxpE4BoeZFUyZ9ym6rqNzDtnaKaeDGwMGRZKTC23",
  "A8EKa9guL1ct9x5EkCvXNg4oXuVSXBk8rUT3tDrGN6W3",
  "CGCQumQNruEqVuW1RsQa4qQKiNv8W2ruLNGmXTSACV45",
  "8JznsC1JKkfoiajt7wnVbX5VJX8KUt8pvPxHtp6rob88",
  "A6tWu6sz1xiyQrRsZMahCgrKhE4EvB7hgpC2RJXJeBsm",
  "7q9ECR84cEuZ1GaUharNgSZvWqqT7minip8qUt7fmWgF",
  "Gq6LJAFUwkRSLhVn25JP19dzstaM8h95FGPFo4CbSXtN",
  "8DwagDdFKBcu5LoUcd62VWJ679Pk6p6v53UFZbUcKZgS",
  "6yuArtKbqZUEVjSxHA9uUTjAPnBfoXw6aaGXivtAK72T",
  "7QynzrzHsfMCQEZ3b2ZkKJEf5xbbekBkqKjAEycjB6jQ",
  "8ZiVtcinQTssZwJBSGFzJU1ZdXmjGB8d7bVGtsXXSYEX",
  "CQLUkxRg85AazTAjhHeu6GF4JExykd2ZnE2ppXCYurUm",
  "BC2YSo59HeZrabMcPhRg7swU8FLkKZRtzzZyYf6bHUbS",
  "E91UX6c2dSHBC8CmaTw1JiYXpy45mzLWhun2S7nAV2BT",
  "8Za4mSzqWHBj72DHJ9kvgAGD7mD9XNogNeo3kvNAKdfp",
  "4vmW7H9ZshQ1Bn4wMvnfG91jinPBZ7t4KkN8tyQVVadr",
  "DNZSLbeb6GwYhntTQugzwsmhmriseuBJaZfYqxdunLv5",
  "4Az9ZEagfLP1gZmbQA8uDjwRoa9PNYrxJoGEgQdG2fhS",
  "AjKZh8fNmrm6Btv1Ly7hYwWFTAUeAzHXfL7HSHL6Mfq5",
  "34YayNFedd9PSC7b296wFxCLNcVV47XN2RwF8m3HpAvK",
  "4jEgMAyRrknmDhG97j7WVVJhUZmZQwxcT1DLFdTNdxS2",
  "fH9ycvLLnQ3DS9nd38yVyVP9RFqxiXYDW9WYfCQaB31",
  "BdjaFyJ9o7GVLofV7ApsqmiuxhU6JB3azmEfwAhqc1DY",
  "4LXy74cNTxyjxo3ivd6g61ajBByRofEFPsJpBX3tr3rG",
  "AXG5ERqi2be4G1sjc9knWbw6FKjY5jUqHSpShPL95kbz",
  "6j6y9XPV75R8RsuKM77pRd25nDkm2GGfSnDdacFtM1CC",
  "FPYCw1MHUMjYAU8FTaogBUCy8syeT3qFJDUNFBrkTFJ8",
  "Cuiy6xnmjZW9UsrwP3xwgnYqvuvf3pmQGWXmePwCuwjn",
  "F295Ut8V1pP5XskpxbbaobQMHUxRCaBnfaCxprLmL5Qs",
  "64wJvNWmpUQ3mcT9FN4Aw7N4AAd6362G5b2z3VbF3Soq",
  "GGAvTbnikJ3wUDdf1jkg5JqSTe4iNR8B1MUhrJZFE8vj",
  "AbAiPGsvUip1nSBABKqSg4MQGp6136hqNxnh6nFHZUQu",
  "ZwrqYNoT3kRM8ArefNPRiMDE6zUTLzn3UsQc4dJpmgk",
  "G6eKsW7AFJ8nvdnqEYKGBjSghLn78mG5pbPKyTEcGyz",
  "9TQtguRe69fUb9LMG6hHRAXjtrzhg6357o6zsoyhrktm",
  "ERCnaTWvMBmge7k9NzYLeZf27zATfpPd5n61mvp7bWY9",
  "W5DiKvhhiV5smnKj1PRiFSwDo1z84ASh8k6jSr5KpG3",
  "9LRDKEjC4J4LabH5L5NG7VQzn4HELKTzh25iLodabzSD",
  "DXr8cARc3Dah5PqkK3YT6Z3iaNya7zAEhJzSHPhF9wZm",
  "Dahb1vfhfuE5GMM1HBxpRfEMjzreM6Q7MP4qRpKncMti",
  "DRAC7wCZh8Csf24QrwBzDGuBuLuykEWtNECgf4ETG21f",
  "4dBWPUJjpM2NSPmarosjt1nXjDeNBECWWP2mNoWKbHDF",
  "E4kQ9VacXQGiHtagHC3ppusuFrcj5NPFPhirhYK87sr",
  "H3QEPsfA6NcRhGrbNzFBWasArs4uRm7Dr1f22ZReNi68",
  "A4D1cUJJGWuwmvF9oM27UUivdbf8xpuxDrxwerz1EqDo",
  "x3d25HMxzDj2mNHB4uxQZmD8crmDyW7QoGYym3if6mY",
  "DtT8rosnMAkNm9DksQZZxwRscQ1C2jAN3M2687Ad8TWw",
  "2yFEoCAzFftKVPr35EWuAFix84EBxj83iwPP3vi4xAWw",
  "DLh1558EQSV9WPJrsJFPcdgsRiTirwy5u5V96xbGujdw",
  "8q6EPkE9Bbp6K4ZSSGuHV4y33YhrfJGVifCkBvBp75dE",
  "BodcvVGbB5SDFQGf5LpKuqBvqCmF3Tjf489TbgMaA5ZW",
  "85fUGUe5dFCuLV5foW1UT4GJfUTdqx4LdumqZKdbxPuj",
  "9sEqhmT5HVtsszZKUoPAesaRYurYAS1dQ9hMF1Fm5Y7i",
  "Hx12A5pwNcquiRtbLVBTuuxVkEHfwDL1ah19RcMHiWHB",
  "HHEEbe5LbRCtVXCMw1fq4skFoJX4raXetXUrJ8ZfgkW5",
  "E73nrfLwepuYfj2rHgAZrMyLutDwEoYP5jErm1gsCmJp",
  "9FyasmbPjJTaRnXFek6TELFPzH1PKkQRiPzeZdbJQmAs",
  "8EK6rSKR3yU5LZXZuSvyBv3eGHtQMAmBGBzfivtNQA8G",
  "2tEc288FucD8qTLurRFBwnq42Kxeh7QmthRf2q1MDPLw",
  "Asf4VVntUdZPFHhXnRFGJUoFgxPLeb84NFeLjQGffgas",
  "Aqo6CLnZJWLasCB9vu5bX4oEP9oKkgbxorQMmjPsg925",
  "9gzHgaEhjAYU2MjbpN49WCE4Rr9XUeQeD3iXeWQ8eZ1G",
  "6GQfrJfeo778EGQJAD1eXsCPHEHsgCtLPR4mEd6sYEQc",
  "CCqDxUabUtphxhhs43GdG39h2hRTYTig1MLC6W2QA6RN",
  "FHWNaD8FtvY7mT3sx7EkaMkKGj8xP2sxfhPJXigEYtSJ",
  "22cgkBMpNyogubsJTaiqG2PiysQBWnRZmzPuTtmaNDvb",
  "AvgBgcTfaecm5paAES4jjrhp6nAJp3qYBii3ndqttRwZ",
  "AJamFLMxWprhX29BDUN6PPbcL74mV4Tbu1FUrbzBCe1v",
  "HqnVvTMAy1dfTveQ21YLo3Sk8tra6hQ9QHvgXE8avhpy",
  "4BQ5sBgWzrdAieDQEA8HNcufk79WS9Nead1TN3q3EhER",
  "5NyEHpinHG1oKgm7kVEpu2wasNirNEXPgYxbiX4ZqmDs",
  "24K1Ue6fGA8iqrhm5nJy969NmSVtBh5aHivmvW6UVjYN",
  "E1L9WtxQ4CUcZeW7w3L1vxydqgRJ8m6ZKuqxaCxmTEis",
  "9G2DvMCvbyamfsgtZ9WuNuMKn95bmLT6NRmWAffxu9Yv",
  "HLkUPbWtzXmUC52NB7EQChxTKZVVW2wkc8pBuYhDJn3p",
  "BmEMinYcEZKFHCd6cbpiWUgXe1PPrv6FnrHR1T35LzGi",
  "Csu4YWUezB2QTNvGaAJJEutC1pjp1Q9szUCraNMWmGEq",
  "7dvxAXnZ9C8D4B55LjrSwXY2kt995KCE4gQMJqm1Ys7a",
  "GGt2PMEnyDiZQgz9uiMaWmvaG5Fo9z1U64s5mNDqecuQ",
  "Ga1aP644fKG6kXZiLw7WS6GBxqPiyhYDcSucN6dXr12y",
  "3EQg563SmEomZNcGE6vqsV5kwn6W66iWuA4NGsUow2a8",
  "AYCVLe2A1dgyGDGgnZhfF9GBBYSBRgFFm96mNjL8UbCc",
  "9fZco9Jf3ZUN1xzuUdiK9NiMLQjpkD3DNXEHcrx841DY",
  "GSbycWFujEvqHKP3F4jg9aZbZE4ewgprCscE7Ho211CM",
  "F1tfHFAMqjPWiQw4JV2bq2dn9W8JUVHJD4FL1hmutGss",
  "AqfA3QheeePcocZ2ufte8axTxMuXLbdB3gC5hjgW8nq4",
  "3vBKERXCuLgD3cYVWfaifPWUG4f5UfodV7pAXdjLiEdv",
  "21tkbaZydbNtfRj2gP2XrPagv9oW1VE7wVqCqUB36apt",
  "3gN6YMXf6FKqBCxuCJ1YGmGiEwqakJ6zc68ngDnEQwem",
  "BQt9yu1jGD8EJ6yx6CtFD6erQNSSQyuvCTKDgFLTCejJ",
  "8GchRas2hS5AhS7NE8ck9mWAfVJeLFrCQHaYvFRdAmfT",
  "8dXVFLwQqPTFMh4icLnTnGDJbgbskzrDLKWXLNAq6dAD",
  "3jeysxWDryL26hJP43jYLkmf5jSZFUbJoyBBBwpeFBFB",
  "9hDvsESTdZ7zXYPWu7EdP9UZchXYMbaXGqBHzPxMpWPm",
  "8aRreHpWkt6x9xTuzLdQWEh9KZ7s2ebA21K3mgcjTM5A",
  "5Kr1uSpmqeXektK6GBfs7g841r73R2WJvQ9ooFFa4PfJ",
  "Ekmrv26dRnjXcAtCRV8QAzFDXPPqCc8LfkR33DNdPgJS",
  "GMHQiqsu2c83LJP7xY17HVHpkYJpzHkW7WSsKt4RhDeF",
  "EmUPTHUbbKagaCTKBfEdEFTda1fkVmevRUTW3gXVDwSM",
  "6VizdymDX466H1atmQxCJmvgESjK97boB7VrPn6Dxa7D",
  "HWTKpB4J7L9D2m4AuVghfMHR2oKdMtDtCAf71buwXuHC",
  "88mRTHZUipw9vpvEQiT53tZN3SeywfiHNdnkwiQHuALK",
  "Bbwn66Z2atWav5XUtgu3uVcQEMQTAubX89wQy4tzyh2o",
  "AK4opuWzzg3wbkJiRoeRe5J3MjLazqZGZcZUhyhw92MN",
  "FTyibPHHXoG1La9VVGTtbXiuXNPybapTD4AQHWyecLLT",
  "BeCycRLumv57fEYobBPkH9Ruvf17zgpCjyLzBUamh8Fs",
  "9QYSMQHVCvag9PHCsKW1bNKKgHGNgwujZYZxS9N6i7FM",
  "27yw5D8uJzMN3uaoSVCAYJRg56jhSXPTHC1Z7cPZZEuW",
  "3j7caue7xL2VPxX6DcpvfcgbEawbcVB1yQNCeLUbLs2V",
  "553trxpoFkn8eLWnmNjRAraTsY4mPHfgVM7JHwGJSzGC",
  "6W3g66i5aeugqxNcYu6BtfAidBWARH2h2eWDYk77c1v8",
  "CS5nDpEYCXFeTMdHuHiSuEVVEAekNwsura9BaBF5jqSF",
  "CBuGTBwjoqAAGu9jBVLQRv5ZvZnTqxnP3dbnBKQYgkTQ",
  "F2aBkcab3SCcddXnZQegYaHWpp3kHM42NNpLMY7eJYP7",
  "6cda8zSy2Cvk5RZBJja1T8hNWn9CQHgxdMpgxZn78pEg",
  "2qoMmx3AWXn47U8G2SDmyshGxYsr8MBLZ8M2T5A1Xn3J",
  "5b6rAWADNrazqHsYtgSC8v33ZCePs8Yfd3c5967Y9Rci",
  "s32urGrE1kWaxezgcjY3J9RKFPxrTn2Jfa5apigB6B3",
  "9bt3QxWuoxYRrxWjumeCihssbnU8Hi2HFAnDc4KkQmx7",
  "7JodwpHCaTGiT1wzbLuGdstJJY92KJAwnnaKveUKeb2Q",
  "E9jwSMHdpv3h86GWEmhJSkuDyDfggqyWgP1So1PBmBQR"
];

function App() {
  const location = useLocation();
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [provider, setProvider] = useState("");
  const [walletKey, setWalletKey] = useState("");
  const [hasNFT, setHasNFT] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [listOfNFTs, setListOfNFTs] = useState([]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const connectWallet = async () => {
    const { solana } = window;

    if (solana) {
      try {
        const response = await solana.connect();
        setWalletKey(response.publicKey.toString());

        const balance = await fetch(`https://api.blockchainapi.com/v1/solana/wallet/mainnet-beta/${response.publicKey.toString()}/nfts`, {
          method: 'GET',
          headers: {
            'APIKeyID': 'dIYwhXCxfmtFBmg', // Ask the blockchain api keys from guys
            'APISecretKey': 'So16jusMmcTCHvr' // Ask the blockchain api keys from guys
          },
        });
        const data = await balance.json();

        const { nfts_owned, nfts_metadata } = data;
        let isEmpty = true;
        const dataOfOwned = [];
        const owns = nfts_owned.forEach(tokenAddress => {
          if(NFTTokensAdresses.includes(tokenAddress)){
            isEmpty = false;
            dataOfOwned.push(nfts_metadata.find(token => token.mint === tokenAddress))
          }
        });

        if(!dataOfOwned.length) {
          setModalContent({
            title: 'Sorry, token ID not found',
            details: (<p>Please try again or contact <a href="mailto:support@valhallapass.ai" target='_blank' rel="noreferrer">support@valhallapass.ai</a></p>)
          });
          setIsModalOpen(true);
          return;
        } else {
          setModalContent({
            title: 'Valhalla Pass NFT token found.',
            details: (<p>Thank You for your trust, as long as You hodl, Lifetime access is assigned to this wallet address. Have an awesome journey!</p>)
          });
          setIsModalOpen(true);
        }
     
          setListOfNFTs(dataOfOwned)
          setHasNFT(!isEmpty);
      } catch (err) {
        alert(err.message);
      }
    }
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0xfszhb', 'template_lfaroen', form.current, 'Mfrx1OW6-mDlISrgz')
      .then((result) => {
          setIsSubscribeModalOpen(true);
      }, (error) => {
          console.log(error);
      });
  };
 
  useEffect(() => {
    const provider = window.solana;
    if (provider) setProvider(provider);
    else setProvider(undefined);
  }, []);

  return (
    <div className={isMobile() ? 'homepage noscroll' : 'homepage' }>
      {isMobile() && (
        <div className="mobile-banner">
          <div>
            <img src={logo} alt="valhalla" />
            <h3>Welcome to ValhallaPass.ai</h3>
            <span className="description">This website requests Desktop Mode</span>
            <div className="socials">
                <a href="https://magiceden.io/marketplace/valhalla_pass?status=%5B%22all%22%5D" target="_blank" rel="noopner noreferrer">
                  <img src={magiceden} alt="" />
                </a>
                <a href="https://twitter.com/valhallapass" target="_blank" rel="noreferrer">
                  <img src={twitter} alt="" />
                </a>
                <a href="https://discord.gg/MSUjWheFM6" target="_blank" rel="noreferrer">
                  <img src={discord} alt="" />
                </a>
            </div>
          </div>
        </div>
      )}
      <div className="banner"><Link to="/pricing">Valhalla Pass Lifetime accesses are officially SOLD OUT!</Link></div>
      <img className="bg-shape small" src={bgShape1} alt="" />
      <img className="bg-shape big" src={bgShape2} alt="" />
      <header className={location.pathname !== "/" ? "section-bg" : null}>
        <Link to="/" className="logo">
          <img src={logo} alt="valhalla" />
        </Link>
        <nav>
          <ul>
            <li><NavLink to="/features">Features</NavLink></li>
            <li><NavLink to="/pricing">Pricing</NavLink></li>
            <li><NavLink to="/careers">Careers</NavLink></li>
            <li><NavLink to="/faq">FAQ</NavLink></li>
            <li><NavLink to="/contact-us">Contact us</NavLink></li>
          </ul>
        </nav>
        <div className="header-controls">
          {!walletKey ? (
            <button className="outlined" onClick={connectWallet}>
              Connect Wallet
            </button>
          ) : (
            <button className="outlined">{walletKey}</button>
          )}
          <a href="https://magiceden.io/marketplace/valhalla_pass?status=%5B%22all%22%5D" target="_blank" rel="noopner noreferrer">
            <img src={magiceden} alt="" />
          </a>
        </div>
      </header>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <footer>
        <div className="bottom-cross-section" />
        <div>
          <div>
            <img src={logopass} alt="" height={80} />
            <form className="email-input" ref={form} onSubmit={sendEmail}>
              <input placeholder="Email" type="email" name="email" />
              <button className="primary" onClick={() => setIsSubscribeModalOpen(true)}>Subscribe</button>
            </form>
          </div>
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/features">Features</Link></li>
              <li><Link to="/pricing">Pricing</Link></li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <a href="mailto:info@valhallapass.ai" target="_blank" rel="noopener noreferrer">General Enquiry</a>
              </li>
              <li>
                <a href="mailto:support@valhallapass.ai" target="_blank" rel="noopener noreferrer">Support</a>
              </li>
              <li>
                <a href="mailto:feedback@valhallapass.ai" target="_blank" rel="noopener noreferrer">Give Feedback</a>
              </li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                <a href="https://magiceden.io/marketplace/valhalla_pass?status=%5B%22all%22%5D" target="_blank" rel="noopner noreferrer">
                  <img src={magiceden} alt="" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/valhallapass" target="_blank" rel="noreferrer">
                  <img src={twitter} alt="" />
                </a>
              </li>
              <li>
                <a href="https://discord.gg/MSUjWheFM6" target="_blank" rel="noreferrer">
                  <img src={discord} alt="" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <span className="description">
          © 2022 Valhalla AG. All Rights Reserved.
        </span>
      </footer>
      <Modal 
        isOpen={isSubscribeModalOpen} 
        onRequestClose={() => setIsSubscribeModalOpen(false)}
        style={{
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.25)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 50,
            },
            content: {
                position: 'relative',
                width: '50vw',
                backgroundColor: '#262626',
                height: 'fit-content',
                textAlign: 'center',
                border: 'none',
                inset: 'initial'
            }
        }}
      >
        <div className="description">Thanks for subscribe!</div>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        className="wl-popup"
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.25)",
            display: "flex",
            justifyContent: "center",
            padding: 50,
          },
          content: {
            position: "relative",
            width: "50vw",
            height: "20vh",
            backgroundColor: "#12131A",
            border: "none",
            borderRadius: "12px",
            inset: "initial",
            margin: "auto",
          },
        }}
      >
        <div style={{ textAlign: "center" }} className="connect-modal">
          <div className="title">
            {modalContent?.title || "Valhalla Pass NFT token found."}
          </div>
          <div className="description">{modalContent?.details}</div>
        </div>
        <button type="button" className="btn btn-secondary btn--modal_close" onClick={handleModalClose}>
            Close
        </button>
      </Modal>
    </div>
  );
}

export default App;
