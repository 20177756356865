import { useState } from 'react';
import Modal from 'react-modal';

import { Select, Input, Textarea } from '../common';

import jobs from './jobs';

import './Careers.scss';

const Careers = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [defaultPosition, setDefaultPosition] = useState(null);

    const handleJobClick = (index) => {
        setModalData(jobs[index]);
        setIsModalOpen(true);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const handleApply = (selectedJob) => {
        setDefaultPosition(selectedJob);
        setTimeout(() => {
            document.getElementById('application-form').scrollIntoView({
                behavior: 'smooth'
            });
        });
        setIsModalOpen(false);
    }

    return (
        <>
            <section className="jobs">
                <h1>Explore popular jobs</h1>
                <h5>Join our innovative and dynamic team.</h5>
                <div className="list">
                    {jobs.map(({ title, location, details }, index) => (
                        <div key={title}>
                            <div className="location">{location}</div>
                            <div className="title">{title}</div>
                            <div className="description" dangerouslySetInnerHTML={{ __html: details }}></div>
                            <button className="primary strict-edges" onClick={() => handleJobClick(index)}>Continue</button>
                        </div>
                    ))}
                </div>
                <Modal 
                    isOpen={isModalOpen} 
                    onRequestClose={handleModalClose}
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.25)',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 50,
                        },
                        content: {
                            position: 'relative',
                            width: '50vw',
                            backgroundColor: '#262626',
                            border: 'none',
                            inset: 'initial'
                        }
                    }}
                >
                    <div>
                        <div className="location">{modalData.location}</div>
                        <div className="title">{modalData.title}</div>
                        <div className="description">{modalData.description}</div>
                        <div className="description" dangerouslySetInnerHTML={{ __html: modalData.details }} />
                    </div>
                    <button className="primary strict-edges" onClick={() => handleApply(modalData.title)}>Apply for this position</button>
                </Modal>
            </section>
            <section id="application-form">
                <h1>Application Form</h1>
                <h5>Join Now</h5>
                <div className="form">
                    <div>
                        <Select options={jobs.map(job => job.title)} label="Position *" defaultValue={defaultPosition} setDefaultValue={setDefaultPosition} />
                        <Input label="First Name *" placeholder="First Name" />
                        <Input label="Last Name *" placeholder="Last Name" />
                        <Input label="Email *" placeholder="xxx@xxx" />
                        <Input label="Phone Number *" placeholder="+1" />
                        <Input label="Resume *" placeholder="Dropbox Google Drive link" />
                        <Input label="Cover Letter *" placeholder="Dropbox Google Drive link" />
                        <Input label="LinkedIn Profile *" placeholder="Profile URL" />
                        <Input label="Website *" placeholder="https://" />
                    </div>
                    <div>
                        <Select options={['Remote']} label="Type of work *" />
                        <Textarea placeholder="Fill here" type="textarea" label="What commercial finance/FP&A experience have you ascertained whilst working for a fast-growth business? *" />
                        <Input label="Do you require visa sponsorship? *" placeholder="YES/NO" />
                        <Input label="Have you been or are you under any financial situation/embarrassment i.e. Are you an undischarged bankrupt or in default under any agreement to which you are a party or by which you may be bound and there is pending litigation, arbitration or administrative proceedings, as the case may be, that might materially affect your solvency? *" placeholder="YES/NO" />
                    </div>
                </div>
                <Input label="I certify that all information given in the application is to my knowledge true and correct and that I have not wilfully suppressed any material fact. I accept that if any of the information given by me in this application is in any way false or incorrect, the company shall have the right, if I am appointed, to dismiss me or terminate my services without notice and without assigning any reason therefor. *" placeholder="YES/NO" />
                <button className="primary strict-edges" onClick={() => setIsSuccessModalOpen(true)}>Submit</button>
                <Modal 
                    isOpen={isSuccessModalOpen} 
                    onRequestClose={() => setIsSuccessModalOpen(false)}
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.25)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 50,
                        },
                        content: {
                            position: 'relative',
                            width: '50vw',
                            backgroundColor: '#262626',
                            height: 'fit-content',
                            textAlign: 'center',
                            border: 'none',
                            inset: 'initial'
                        }
                    }}
                >
                    <div className="description">Thanks! Your application has been submitted!</div>
                </Modal>
            </section>
        </>
    );
};

export default Careers;